<template>
<div>
    <ChatX1 v-if="X1 && !X2 && !X3 && !X4" :chats="chats" :chatStart="chatStart" :callback="callback" :stopSkip="stopSkip"></ChatX1>
    <ChatX2 v-if="X1 && X2 && !X3 && !X4"></ChatX2>
    <ChatX3 v-if="X1 && X2 && X3 && !X4"></ChatX3>
    <ChatX4 v-if="X1 && X2 && X3 && X4"></ChatX4>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components:{
    ChatX1: () => import(/* webpackChunkName: "component-chatx1" */ "@/components/tutorial/ChatX1.vue"),
    ChatX2: () => import(/* webpackChunkName: "component-chatx2" */ "@/components/tutorial/ChatX2.vue"),
    ChatX3: () => import(/* webpackChunkName: "component-chatx3" */ "@/components/tutorial/ChatX3.vue"),
    ChatX4: () => import(/* webpackChunkName: "component-chatx4" */ "@/components/tutorial/ChatX4.vue")
},
  computed: mapState({
    //
  }),
  props:[
    'X1',
    'X2',
    'X3',
    'X4',
    'chats',
    'chatStart',
    'callback',
    'stopSkip'
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  }
}
</script>